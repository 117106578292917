//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import Notice from '../components/templates/Notice';

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const NotFound = () => {
  return (
    <Notice
      title='No resource found'
      paragraphOne='Please double check the url and try again'
    />
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default NotFound;
