//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  ClearMeetsCard,
  createEmptyMeets,
  MeetsCard,
  MeetsProps
} from "../components/elements/Meets";
import { Box, Button, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Template from "../components/templates/Template";
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Heading } from "../components/elements/Text";
import { Line } from "../components/elements/Line";
import Firebase from "../firebase";
import { sx } from "../utils";
import { Spinner } from "../components/elements/Spinner";
import Firestore from "../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Main Page Component
//////////////////////////////////////////////////////////////////////////////////////////

const MyMeets = () => {
  const navigate = useNavigate()
  const [meetings, setMeetings] = useState<MeetsProps[]>()

  const fetch = async () => {
    const mymeets = Firebase.auth.currentUser
      ? await Firestore.getMyMeets()
      : []
    setMeetings(mymeets)
  }

  useEffect(() => {
    Firebase.onAuthChange(async () => {
      if (!Firebase.auth.currentUser) navigate('/')
      await fetch()
    })
  }, [navigate])

  if (meetings === undefined) {
    return (
      <Template center>
        <Spinner />
      </Template>
    )
  }

  return (
    <Template>
      <Heading bold>My Meets</Heading>
      <Line label={`${meetings.length >= 10 ? '9+' : meetings.length} Meets`} sep={3} />
      <Container>
        {
          meetings.map((meeting, index) => (
            <MeetsCard
              key={index}
              position={index + 1}
              meet={meeting}
            />
          ))
        }
        <ClearMeetsCard>
          <AddButton onClick={() => {
            if (!Firebase.auth.currentUser) navigate('/auth')
            setMeetings([...meetings, createEmptyMeets()])
          }}>
            <AddIcon sx={{ fontSize: 50, color: '#8c8c8c', mb: 3 }} />
            <Typography>Create New Meet</Typography>
          </AddButton>
        </ClearMeetsCard>
      </Container>
    </Template>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Container = styled(Box)(sx({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

const AddButton = styled(Button)(sx({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  borderRadius: 3
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default MyMeets;
