//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import styled from "@emotion/styled"
import { LoadingButton } from "@mui/lab"
import { Card, CardContent, CardHeader } from "@mui/material"
import { sx } from "../../utils"
import GoogleIcon from '@mui/icons-material/Google';
import Firebase from "../../firebase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Body, Heading } from "../elements/Text";
import Logo from '../../images/logos/logo-bare.png';

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const AuthTemplate = (props: {
  title?: string;
  subtitle?: string;
  googleSignIn?: boolean;
  logo?: boolean;
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate()
  const { title, subtitle, children } = props

  const GoogleSignIn = async () => {
    toast.info('Signing In...')
    if (await Firebase.GoogleSignIn()) {
      toast.success('Successfully Signed In')
      navigate('/')
    }
  }

  return (
    <CardContainer>
      {/* {props.logo && <CardSectionContainer>
        <img
          src={Logo}
          alt='logo'
          style={{ width: '40%', aspectRatio: 1 }}
        />
      </CardSectionContainer>} */}
      <CardSectionContainer>
        <CardHeader
          title={title ? <Heading size={18} sep={1} center bold>{title}</Heading> : undefined}
          subheader={subtitle ? <Body size={12} center>{subtitle}</Body> : undefined}
        />
      </CardSectionContainer>
      <CardSectionContainer>
        {children}
        {
          props.googleSignIn
            ? <>
              {/* <Line
                small
                sep={3}
                label="OR"
                width={'85%'}
                outlined
              /> */}
              <LongButton
                variant="outlined"
                startIcon={<GoogleIcon />}
                onClick={GoogleSignIn}
              >
                Continue With Google
              </LongButton>
            </>
            : <></>
        }
      </CardSectionContainer>
    </CardContainer>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const CardContainer = styled(Card)(sx({
  maxWidth: 320,
  border: 0.5,
  borderColor: '#8c8c8c',
  boxShadow: 0,
  m: 0.5,
  width: '100%',
  borderRadius: 3,
  pt: 2,
  pb: 2
}))

const CardSectionContainer = styled(CardContent)(sx({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  pt: 0.5,
  pb: 0.5
}))

const LongButton = styled(LoadingButton)(sx({
  width: '85%',
  m: 0.5
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default AuthTemplate;