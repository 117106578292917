//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import './index.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReportHandler } from 'web-vitals';
import Bookmarks from './pages/Bookmarks';
import Info from './pages/Info';
import Main from './pages/Main';
import MyMeets from './pages/MyMeets';
import NotFound from './pages/NotFound';
import Auth from './pages/Auth';
import Validate from './pages/Validate';
import OneSearch from './pages/OneSearch';
import Version from './pages/Version';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

//////////////////////////////////////////////////////////////////////////////////////////
// Theming
//////////////////////////////////////////////////////////////////////////////////////////

const theme = createTheme({
  typography: {
    fontFamily: 'revert',
    allVariants: {
      color: '#595959'
    }
  },
  palette: {
    primary: { main: "#595959" },
    secondary: { main: "#5E72E4" }
  }
});

//////////////////////////////////////////////////////////////////////////////////////////
// Root Render
//////////////////////////////////////////////////////////////////////////////////////////

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Main />} />
          <Route path="/id/:lid" element={<OneSearch />} />
          <Route path="info" element={<Info />} />
          <Route path="mymeets" element={<MyMeets />} />
          <Route path="bookmarks" element={<Bookmarks />} />
          <Route path="auth" element={<Auth />} />
          <Route path="version" element={<Version />} />
          <Route path="validate" element={<Validate />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
    <ToastContainer position='bottom-left' autoClose={3000} hideProgressBar />
  </React.StrictMode>
);

//////////////////////////////////////////////////////////////////////////////////////////
// Web Vitals
//////////////////////////////////////////////////////////////////////////////////////////

const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

// TODO: change this to analytics endpoint
reportWebVitals(console.log);