//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  AppBar,
  Avatar,
  Box,
  IconButton,
  Toolbar,
  Tooltip
} from "@mui/material";
import styled from "@emotion/styled";
import LogoFull from '../../images/logos/logo-horizontal.png'
import LogoBare from '../../images/logos/logo-bare.png'
import { sx } from "../../utils";
import { useNavigate } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import VideocamIcon from '@mui/icons-material/Videocam';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import Firebase from "../../firebase";
import { useState } from "react";
import { Spinner } from "../elements/Spinner";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const IconWrapper = (props: {
  title: string;
  href: string;
  size?: "small" | "medium" | "large";
  children?: React.ReactNode;
}) => {
  const navigate = useNavigate()

  return (
    <Tooltip title={props.title}>
      <IconButton
        size={props.size}
        onClick={() => navigate(props.href)}
      >
        {props.children}
      </IconButton>
    </Tooltip>
  )
}

const NavButtonsGroup = (props: {
  loggedIn: boolean;
}) => {
  if (!props.loggedIn) {
    return (
      <ButtonGroup>
        <IconWrapper size="medium" title="Info" href="/info">
          <InfoIcon sx={{ fontSize: 25 }} />
        </IconWrapper>
        <IconWrapper size="large" title="Sign In" href="/auth">
          <AvatarFrame>
            <KeyIcon color='primary' />
          </AvatarFrame>
        </IconWrapper>
      </ButtonGroup>
    )
  }

  return (
    <ButtonGroup>
      <IconWrapper size="medium" title="Info" href="/info">
        <InfoIcon sx={{ fontSize: 25 }} />
      </IconWrapper>
      <IconWrapper size="medium" title="My Meets" href="/mymeets">
        <VideocamIcon sx={{ fontSize: 31 }} />
      </IconWrapper>
      <IconWrapper size="medium" title="Bookmarks" href="/bookmarks">
        <BookmarkIcon sx={{ fontSize: 25 }} />
      </IconWrapper>
      <IconWrapper size="large" title="Account" href="/auth">
        <AvatarFrame>
          <PersonIcon color='primary' />
        </AvatarFrame>
      </IconWrapper>
    </ButtonGroup>
  )
}

const Template = (props: {
  children?: React.ReactNode;
  center?: boolean;
}) => {
  const navigate = useNavigate()
  const [loggedIn, setLoggedIn] = useState<boolean>()

  Firebase.onAuthChange(user => {
    if (loggedIn === undefined) setLoggedIn(!!user)
    else if (user && !loggedIn) setLoggedIn(true)
    else if (!user && loggedIn) setLoggedIn(false)
  })

  const Logo = (props: { image: string }) => (
    <img
      src={props.image}
      alt='logo'
      style={{ width: '100%' }}
      onClick={() => navigate('/')}
    />
  )

  if (loggedIn === undefined) {
    return (
      <CenteredContainer>
        <Spinner />
      </CenteredContainer>
    )
  }

  return (
    <Container>
      <Navbar>
        <NavCenter>
          <LogoFullContainer>
            <Logo image={LogoFull} />
          </LogoFullContainer>
          <LogoBareContainer>
            <Logo image={LogoBare} />
          </LogoBareContainer>
          <NavButtonsGroup loggedIn={loggedIn} />
        </NavCenter>
      </Navbar>
      {
        props.center
          ? <CenteredCanvas>{props.children}</CenteredCanvas>
          : <UncenteredCanvas>{props.children}</UncenteredCanvas>
      }
    </Container>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Container = styled(Box)(sx({
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: '#f5f5f5'
}))

const CenteredContainer = styled(Container)(sx({
  justifyContent: 'center'
}))

const Canvas = styled(Box)(sx({
  display: 'flex',
  width: '95%',
  maxWidth: { xs: 'none', md: 1280 },
  minHeight: 500,
  alignItems: 'center',
  flexDirection: 'column'
}))

const UncenteredCanvas = styled(Canvas)(sx({
  mb: 4
}))

const CenteredCanvas = styled(Canvas)(sx({
  justifyContent: 'center',
  height: 'calc(100vh - 160px)',
}))

const LogoFullContainer = styled(Box)(sx({
  display: { xs: 'none', sm: 'flex' },
  width: 150
}))

const LogoBareContainer = styled(Box)(sx({
  display: { xs: 'flex', sm: 'none' },
  width: 45
}))

const Navbar = styled(AppBar)(sx({
  position: 'static',
  boxShadow: 0,
  backgroundColor: 'white',
  borderTop: 0.5,
  borderBottom: 0.5,
  borderColor: '#8c8c8c',
  display: 'flex',
  alignItems: 'center',
  mt: { xs: 4, md: 6 },
  mb: 3
}))

const NavCenter = styled(Toolbar)(sx({
  width: { xs: '85%', md: '67%' },
  maxWidth: { xs: 600, md: 1080 },
  justifyContent: 'space-between'
}))

const ButtonGroup = styled(Box)(sx({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}))

const AvatarFrame = styled(Avatar)(sx({
  width: 37,
  height: 37,
  bgcolor: '#d9d9d9'
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Template;
