//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { InputAdornment, SxProps, TextField } from "@mui/material";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Input = (props: {
  inputRef: React.MutableRefObject<string>;
  title: string;
  max?: number;
  multiline?: boolean;
  noOutline?: boolean;
  sx?: SxProps;
  small?: boolean;
  password?: boolean;
  icon?: React.ReactNode;
}) => {
  const { max, multiline, title, inputRef, noOutline, small, sx } = props

  return (
    <TextField
      type={props.password ? 'password' : undefined}
      size={small ? "small" : undefined}
      label={title}
      variant={noOutline ? "standard" : undefined}
      defaultValue={inputRef.current}
      onChange={e => inputRef.current = e.target.value}
      multiline={multiline}
      rows={4}
      sx={{ width: '100%', ...sx }}
      inputProps={{ maxLength: max || undefined }}
      InputProps={{
        startAdornment: props.icon
          ? (<InputAdornment position="start">
            {props.icon}
          </InputAdornment>)
          : undefined
      }}
    />
  )
}