//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import styled from '@emotion/styled';
import Logo from '../../images/logos/logo-full.png';
import { Box, Divider, Typography } from "@mui/material";
import { sx } from '../../utils';

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Notice = (props: {
  title: string;
  paragraphOne?: string;
  paragraphTwo?: string;
}) => {
  return (
    <Container>
      <ImageContainer>
        <img src={Logo} alt='logo' style={{ width: '100%' }} />
      </ImageContainer>
      <Line />
      <Paragraph><b>{props.title}</b></Paragraph>
      <Paragraph>{props.paragraphOne}</Paragraph>
      <Paragraph>{props.paragraphTwo}</Paragraph>
    </Container>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Container = styled(Box)(sx({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}))

const ImageContainer = styled(Box)(sx({
  width: { xs: 200, md: 300 }
}))

const Line = styled(Divider)(sx({
  width: '50%',
  maxWidth: 400,
  mt: 4,
  mb: 4,
}))

const Paragraph = styled(Typography)(sx({
  letterSpacing: 0.5,
  fontSize: { xs: 16, md: 20 },
  textAlign: 'center',
  width: '50%',
  maxWidth: 400,
  mb: 1,
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Notice;
