//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Display } from "../components/elements/Display";
import { Line } from "../components/elements/Line";
import { Spinner } from "../components/elements/Spinner";
import { Heading } from "../components/elements/Text";
import { NoResults } from "../components/templates/NoResults";
import Template from "../components/templates/Template";
import Firebase from "../firebase";
import { Utils, sx } from "../utils";
import Firestore, { BookmarksResponseProps } from "../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Main Page Component
//////////////////////////////////////////////////////////////////////////////////////////

const Bookmarks = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState<BookmarksResponseProps>()

  const fetch = async () => {
    let meets = await Firestore.getBookmarks()
    const account = await Firestore.getAccount()
    meets = Utils.addBookmarksAndLikesToMeets(
      account.likes,
      account.bookmarks,
      meets
    )
    setResponse(meets)
  }

  useEffect(() => {
    Firebase.onAuthChange(async () => {
      if (!Firebase.auth.currentUser) navigate('/')
      await fetch()
    })
  }, [navigate])

  if (response === undefined) {
    return (
      <Template center>
        <Spinner />
      </Template>
    )
  }

  return (
    <Template>
      <Heading bold>Bookmarks</Heading>
      <Line label={`${response.length >= 10 ? '9+' : response.length} Bookmarks`} sep={3} />
      <WrappedContainer sx={{ width: '100%' }}>
        {
          response.length > 0
            ? response.map(data => (<Display data={data} key={data.lid} />))
            : <NoResults text="No Bookmarks Found" />
        }
      </WrappedContainer>
    </Template>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const WrappedContainer = styled(Box)(sx({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Bookmarks;
