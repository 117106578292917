//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { Chip, Divider } from "@mui/material";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Line = (props: {
  label?: string;
  width?: string | number;
  sep?: number;
  outlined?: boolean;
  small?: boolean;
}) => {
  return (
    <Divider sx={{
      width: props.width || '90%',
      mb: props.sep || 0,
      mt: props.sep || 0
    }}>
      {
        props.label
          ? <Chip
            variant={props.outlined ? "outlined" : undefined}
            size={props.small ? "small" : undefined}
            label={props.label}
          />
          : null
      }
    </Divider>
  )
}