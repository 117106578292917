//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import Template from "../components/templates/Template";
import { sx, Utils } from "../utils";
import { Display } from "../components/elements/Display";
import { Line } from "../components/elements/Line";
import { NoResults } from "../components/templates/NoResults";
import { useParams } from "react-router-dom";
import Firebase from "../firebase";
import { Spinner } from "../components/elements/Spinner";
import { uuidv4 } from "@firebase/util";
import Firestore, { MeetsResponseProps } from "../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const OneSearch = () => {
  const [response, setResponse] = useState<MeetsResponseProps>()

  const { lid } = useParams()

  const fetch = async (lid: string) => {
    const meets = await Firestore.getSingleMeets(lid)
    setResponse(meets)
    if (Firebase.auth.currentUser) {
      const account = await Firestore.getAccount()
      meets.pages.items = Utils.addBookmarksAndLikesToMeets(
        account.likes,
        account.bookmarks,
        meets.pages.items
      )
      setResponse({ ...meets })
    }
  }

  useEffect(() => {
    Firebase.onAuthChange(async () => await fetch(lid ?? ''))
  }, [lid])

  if (response === undefined) {
    return (
      <Template center>
        <Spinner />
      </Template>
    )
  }

  return (
    <Template>
      <Line sep={1} label={`Single Search`} />
      <WrappedContainer>
        {
          response.pages.items.length > 0
            ? response.pages.items.map(data => (<Display data={data} key={uuidv4()} />))
            : <NoResults />
        }
      </WrappedContainer>
    </Template>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const WrappedContainer = styled(Box)(sx({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  mt: 2
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default OneSearch;
