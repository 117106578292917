//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from "@mui/material";
import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { Globals, sx, Utils } from "../../utils";
import { MeetsTypes, meetsTypeToImage } from "./Meets";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TheatersIcon from '@mui/icons-material/Theaters';
import FlagIcon from '@mui/icons-material/Flag';
import { useNavigate } from "react-router-dom";
import Firebase from "../../firebase";
import { toast } from "react-toastify";
import { Body } from "./Text";
import { Dialog } from "./Dialog";
import { Input } from "./Input";
import Firestore from "../../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Type(s)
//////////////////////////////////////////////////////////////////////////////////////////

export interface ListingProps {
  lid: string;
  title: string;
  categories: string[];
  type: MeetsTypes;
  startTime: string;
  endTime: string;
  description: string;
  url: string;
}

export interface DisplayProps extends ListingProps {
  date: string;
  likes: number;
  liked: boolean;
  bookmarks: number;
  bookmarked: boolean;
}

export interface CategoryProps {
  category: string;
  count: number;
}

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Display = (props: {
  data: DisplayProps;
}) => {
  const navigate = useNavigate()

  const { data } = props

  data.description = Utils.maskProfanity(data.description)
  data.title = Utils.maskProfanity(data.title)
  data.categories = data.categories.map(category => Utils.maskProfanity(category))

  const reportRef = useRef<string>('')

  const [more, setMore] = useState<boolean>(false);
  const [menu, setMenu] = useState<HTMLElement>();

  const [like, setLike] = useState<boolean>(props.data.liked);
  const [bookmark, setBookmark] = useState<boolean>(props.data.bookmarked);

  const [reportDialog, setReportDialog] = useState<boolean>(false);
  const [hrefDialog, setHrefDialog] = useState<boolean>(false);

  if (!Utils.isValidISOString(data.date))
    data.date = new Date(0).toISOString()
  if (!Utils.isValidISOString(data.startTime))
    data.startTime = new Date(Date.now() - Utils.minutes(2)).toISOString()
  if (!Utils.isValidISOString(data.endTime))
    data.endTime = new Date(Date.now() - Utils.minutes(1)).toISOString()

  const toggleBookmark = async () => {
    setBookmark(!bookmark)
    if (!await Firestore.postSetBookmark(!bookmark, data.lid)) {
      setBookmark(!bookmark)
    }
  }

  const toggleLike = async () => {
    setLike(!like)
    if (await Firestore.postSetLike(!like, data.lid)) {
      setLike(!like)
    }
  }

  const copyListingLink = () => {
    Utils.copyListingLink(data.lid)
    setMenu(undefined)
  }

  const copyMeetingLink = () => {
    Utils.copyMeetingLink(data.url)
    setMenu(undefined)
  }

  const openReportDialog = () => {
    if (!Firebase.auth.currentUser) navigate('/auth')
    setReportDialog(true)
    setMenu(undefined)
  }

  const report = async () => {
    await Firestore.postReport(data.lid, reportRef.current)
    reportRef.current = ''
    toast.success("Reported Meeting")
  }

  // const background = isLive ? '#fffafa' : isNew ? '#f7f9ff' : undefined

  const statusLabel = Utils.isMeetingLive(data.startTime, data.endTime)
    ? <StatusLabel label="Live" color="#ff6161" />
    : Utils.isMeetingNew(data.date)
      ? <StatusLabel label="New" color='#6180f2' />
      : <></>

  return (
    <CardContainer>
      <Dialog
        title="Report Meeting?"
        open={reportDialog}
        setOpen={setReportDialog}
        action={report}
        content={
          <>
            <Typography sx={{ mb: 0.5 }}>
              Would you like to report {data.title}?
            </Typography>
            <Input
              noOutline
              title="Reason"
              max={Globals.MAX_REPORT_REASON_LENGTH}
              inputRef={reportRef}
            />
          </>
        }
        yes='Report'
        no='Cancel'
      />
      <Dialog
        title="Open Link?"
        open={hrefDialog}
        setOpen={setHrefDialog}
        action={() => Utils.openUrlInNewTab(data.url)}
        content={
          <>
            You are about to visit <b><u>{data.url}</u></b>.
            Only continue if you trust this URL.
          </>
        }
        yes='Continue'
        no='Close'
      />
      <CardHeader
        avatar={
          <Tooltip title={Utils.capitalise(data.type, '-')}>
            <img
              src={meetsTypeToImage(data.type)}
              alt={data.type}
              style={{ height: 33, margin: 5 }}
            />
          </Tooltip>
        }
        action={
          <IconButton onClick={e => setMenu(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        }
        title={data.title}
        subheader={
          <SubheaderContainer>
            <Box sx={{ mr: 1.5 }}>{data.type}</Box>
            {statusLabel}
          </SubheaderContainer>
        }
      />
      <Menu
        anchorEl={menu}
        open={!!menu}
        onClose={() => setMenu(undefined)}
        PaperProps={{ elevation: 0, sx: { border: 0.5, borderColor: '#8c8c8c' } }}
      >
        <MenuItem onClick={copyMeetingLink}>
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy Link</ListItemText>
        </MenuItem>
        <MenuItem onClick={copyListingLink}>
          <ListItemIcon>
            <TheatersIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy Meet</ListItemText>
        </MenuItem>
        <MenuItem onClick={openReportDialog}>
          <ListItemIcon>
            <FlagIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Report</ListItemText>
        </MenuItem>
      </Menu>
      <CardContent>
        <Body sep={1.5}>
          {data.description}
        </Body>
        <Box sx={{ mb: 1.5 }}>
          {
            data.categories.map((category, i) => (
              <Chip
                key={i}
                label={category}
                size='small'
                sx={{ mr: 0.5, mb: 0.5 }}
              />
            ))
          }
        </Box>
        <Button
          variant="outlined"
          endIcon={<NavigateNextIcon />}
          sx={{ width: '100%' }}
          onClick={() => {
            if (data.type === MeetsTypes.OTHER) setHrefDialog(true)
            else Utils.openUrlInNewTab(data.url)
          }}
        >
          Join
        </Button>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton onClick={async () => {
          if (!Firebase.auth.currentUser) navigate('/auth')
          await toggleLike()
        }}>
          {
            like
              ? <FavoriteIcon sx={{ color: '#f47359' }} />
              : <FavoriteBorderIcon sx={{ color: '#f47359' }} />
          }
        </IconButton>
        <IconButton onClick={async () => {
          if (!Firebase.auth.currentUser) navigate('/auth')
          await toggleBookmark()
        }}>
          {
            bookmark
              ? <BookmarkIcon sx={{ color: '#f5c014' }} />
              : <BookmarkBorderIcon sx={{ color: '#f5c014' }} />
          }
        </IconButton>
        <IconButton onClick={() => setMore(!more)} sx={{ marginLeft: 'auto', }}>
          {more ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </CardActions>
      <Collapse in={more} timeout="auto" unmountOnExit>
        <Divider />
        <CardContent>
          <Body sep={2}>Meeting Details</Body>
          <DetailsContainer sx={{ mb: 1 }}>
            <CalendarMonthIcon fontSize="small" sx={{ mr: 1, color: '#00a806' }} />
            <Body>
              {Utils.formatTime(data.startTime)} ~ {Utils.formatTime(data.endTime)}
            </Body>
          </DetailsContainer>
          <DetailsContainer sx={{ mb: 1 }}>
            <AccessTimeIcon fontSize="small" sx={{ mr: 1, color: '#5e72e4' }} />
            <Body>{Utils.getDuration(data.startTime, data.endTime)}</Body>
          </DetailsContainer>
          {/* <DetailsContainer>
            <FavoriteBorderIcon fontSize="small" sx={{ mr: 1, color: '#f47359' }} />
            <Body sx={{ mr: 2 }}>{data.likes}</Body>
            <BookmarkBorderIcon fontSize="small" sx={{ mr: 1, color: '#f5c014' }} />
            <Body>{data.bookmarks}</Body>
          </DetailsContainer> */}
          <DetailsContainer>
          </DetailsContainer>
        </CardContent>
      </Collapse>
    </CardContainer>
  )
}

const StatusLabel = (props: {
  label: string;
  color: string;
}) => {
  return (
    <Tooltip title={props.label}>
      <Label
        label={props.label}
        size="small"
        sx={{ backgroundColor: props.color }}
      />
    </Tooltip>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const CardContainer = styled(Card)(sx({
  maxWidth: 380,
  border: 0.5,
  borderColor: '#8c8c8c',
  boxShadow: 0,
  m: 0.5,
  width: '100%',
  borderRadius: 3
}))

const DetailsContainer = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
}))

const SubheaderContainer = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap'
}))

const Label = styled(Chip)(sx({
  color: 'white',
  height: 15,
  fontSize: 9,
  fontWeight: 'bold'
}))
