//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import Notice from '../components/templates/Notice';
import Configs from '../configs';

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Version = () => {
  return (
    <Notice
      title='Developer Logs'
      paragraphOne={`Version: ${Configs.version || 'N/A'}`}
    />
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Version;
