//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import Template from "../components/templates/Template";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useEffect } from "react";
import Firebase from "../firebase";
import { Spinner } from "../components/elements/Spinner";
import AuthTemplate from "../components/templates/AuthTemplate";
import Firestore from "../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Validate = () => {
  const navigate = useNavigate()
  const link = window.location.href

  useEffect(() => {
    (async () => {
      if (!Firebase.isSignInLinkValid(link)) navigate('/')
      if (await Firebase.signInWithLink(link)) {
        await Firestore.postAccount()
        toast.success('Successfully Signed In')
      }
      navigate('/')
    })()
  }, [navigate, link])

  return (
    <Template center>
      <AuthTemplate>
        <Spinner text="Signing In..." />
      </AuthTemplate>
    </Template>
  );
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Validate;
