//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  Box,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Button
} from "@mui/material";
import styled from "@emotion/styled";
import Template from "../components/templates/Template";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { sx } from "../utils";
import { useState } from "react";
// import MusicNoteIcon from '@mui/icons-material/MusicNote';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GavelIcon from '@mui/icons-material/Gavel';
// import RedditIcon from '@mui/icons-material/Reddit';
import { Body, Heading } from "../components/elements/Text";
import { Line } from "../components/elements/Line";
import { Dialog } from "../components/elements/Dialog";
import Configs from "../configs";

//////////////////////////////////////////////////////////////////////////////////////////
// Type(s)
//////////////////////////////////////////////////////////////////////////////////////////

interface FAQProps {
  question: string;
  answer: string;
}

interface DialogProps {
  title: string;
  body: string;
}

//////////////////////////////////////////////////////////////////////////////////////////
// Content(s)
//////////////////////////////////////////////////////////////////////////////////////////

const FAQContent: FAQProps[] = [
  // {
  //   question: "What personal data do you store and share?",
  //   answer: `
  //     The only personal data that we store is your email address, 
  //     which is not shared with any other users or third parties.
  //     We do not collect or store any other personal information, 
  //     such as your name, phone number, or address. However in the 
  //     future, we may collect anonymous user data with your permission 
  //     solely for the purpose of improving our services.
  //   `
  // },
  {
    question: "Are meetings on GeoMeets safe?",
    answer: `
      While we have protocols in place to discourage malicious links from
      being posted on our platform, we highly recommend users to practise 
      caution at all times specifically when visiting "other" links.
    `
  },
  {
    question: "How do I post my own meeting?",
    answer: `
      To post your own meeting, a personal account is required. 
      Once you are signed in, you can click on the video button on 
      top right to access the My Meets page. From there, you can create a new meeting
      by filling the required fields and clicking the "UPDATE" button.
    `
  },
]

const TermsContent: DialogProps[] = [
  {
    title: '1. Introduction',
    body: `
      These Terms and Conditions written on this webpage shall manage your use of our 
      website, GeoMeets accessible at geomeets.com.
    `
  },
  {
    title: '2. Conditional Usage',
    body: `
      By using our website, you accept these terms and conditions in full. If you 
      disagree with the terms and conditions or any part, you must not use our website.
    `
  },
  {
    title: '3. License',
    body: `
      Unless otherwise stated, we or our licensors own the intellectual property rights 
      of the website. Subject to the following conditions, all these intellectual property 
      rights are reserved. You must not: republish material from this website (including 
      republication on another website); sell, rent or sub-license material from the website; 
      show any material from the website in public; reproduce, duplicate, copy or otherwise 
      exploit material on our website for a commercial purpose; edit or otherwise modify any 
      material on the website; or redistribute material from this website except for content 
      specifically and expressly made available for redistribution.
    `
  },
  {
    title: '4. Acceptable Use',
    body: `
      You must not use our website in any way that causes, or may cause, damage to the website 
      or impairment of the availability or accessibility of the website; or in any way which is 
      unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, 
      fraudulent or harmful purpose or activity. You must not use our website to copy, store, 
      host, transmit, send, use, publish or distribute any material which consists of (or is 
      linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or 
      other malicious computer software. You must not conduct any systematic or automated data 
      collection activities (including without limitation scraping, data mining, data extraction 
      and data harvesting) on or in relation to our website without the express written consent 
      of GeoMeets. You must not use our website to transmit or send unsolicited commercial 
      communications. You must not use our website for any purposes related to marketing without 
      the express written consent of GeoMeets.
    `
  },
  {
    title: '5. Restricted Access',
    body: `
    GeoMeets reserves the right to restrict access to areas of our website, or indeed our whole 
    website, at GeoMeets' discretion and without notice. GeoMeets may disable your account at any 
    time in GeoMeet's sole discretion without notice or explanation.
    `
  },
  {
    title: '6. User Content',
    body: `
      By using the website, any user content copyright belongs to the users. The user grants us
      worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, 
      translate and distribute their user content in order for us to perform the required service.
      However, we are under no obligation to manage and store user's data nor are we reponsible
      for any damages done in an event of data loss or any unexpected failures. 
    `
  },
  {
    title: '7. Disclaimer',
    body: `
      GeoMeets is under no circumstance responsible for any content uploaded by users to the site.
      While we shall take reasonable measures to remove content that is unlawful or violates 
      our terms and conditions, GeoMeets will not take any responsibility for any content 
      that is not removed.
    `
  }
]

const PrivacyContent: DialogProps[] = [
  {
    title: '1. Introduction',
    body: `
      GeoMeets ("us", "we", or "our") operates the geomeets.com website (the "Service"). Our Privacy 
      Policy governs your visit to geomeets.com, and explains how we collect, safeguard and disclose 
      information that results from your use of our Service. We use your data to provide and improve 
      the Service. By using the Service, you agree to the collection and use of information in 
      accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used 
      in this Privacy Policy have the same meanings as in our Terms and Conditions.
    `
  },
  {
    title: '2. Definitions',
    body: `
      Service: Service is the geomeets.com website operated by GeoMeets; Personal Data: Personal Data 
      means data about a living individual who can be identified from those data (or from those and 
      other information either in our possession or likely to come into our possession); Usage Data: 
      Usage Data is data collected automatically either generated by the use of the Service or from 
      the Service infrastructure itself (for example, the duration of a page visit).
    `
  },
  {
    title: '3. Information Collection and Use',
    body: `
      We collect limited types of information for various purposes to provide and improve our Service 
      to you.
    `
  },
  {
    title: '4. Types of Data Collected',
    body: `
      Personal Data: While using our Service, you may optionally disclose personally 
      identifiable information that can be used to contact or identify you ("Personal Data"). 
      Personally identifiable information that we may use currently is only limited to your email 
      address. Usage Data: while we do not currently collect it, we may in the future collect 
      information how the Service is accessed and used ("Usage Data"). This Usage Data may include 
      information such as your computer's Internet Protocol address (e.g. IP address), browser 
      type, browser version, the pages of our Service that you visit, the time and date of your 
      visit, the time spent on those pages, unique device identifiers and other diagnostic data. 
    `
  },
  {
    title: '5. Use of Data',
    body: `
      GeoMeets uses the collected data for various purposes: To provide and maintain the Service;
      To help identify you as a unique user in our Service; To allow consented data do be 
      accurately displayed in our Service;
    `
  },
]

//////////////////////////////////////////////////////////////////////////////////////////
// Main Page Component
//////////////////////////////////////////////////////////////////////////////////////////

const Info = () => {
  const [terms, setTerms] = useState<boolean>(false)
  const [privacy, setPrivacy] = useState<boolean>(false)

  const DialogContent = (props: {
    contents: DialogProps[]
  }) => {
    return (
      <>
        {
          props.contents.map(content => (
            <>
              <Heading underline size={17} sep={1}>
                {content.title}
              </Heading>
              <Body sep={2}>
                {content.body}
              </Body>
            </>
          ))
        }
      </>
    )
  }

  return (
    <Template>
      <Dialog
        title='Terms and Conditions'
        open={terms}
        setOpen={setTerms}
        content={<DialogContent contents={TermsContent} />}
        no='Close'
        dividers
      />
      <Dialog
        title='Privacy Policy'
        open={privacy}
        setOpen={setPrivacy}
        content={<DialogContent contents={PrivacyContent} />}
        no='Close'
        dividers
      />
      <Container>
        <Heading bold>General Information</Heading>
        <Line label="FAQs" sep={3} />
        <FAQs faqs={FAQContent} />
        <Line label="General" sep={3} />
        <GeneralContainer>
          <Heading size={18} sep={1}>About</Heading>
          <Body size={15} center>
            GeoMeets is a free, meetings curation website. Post your
            meetings created via a variety of vendors and develop a
            meaningful community.
            {/* For any questions or enquiries,
            contact <b>{Configs.email}</b>. */}
          </Body>
          <Line sep={3} />
          {/* <Heading size={18} sep={1}>Socials</Heading>
          <Body size={15} center sep={2}>
            Follow us on the following social platforms!
          </Body>
          <ButtonContainer>
            <LinkButton
              title="TikTok"
              icon={<MusicNoteIcon />}
              url="https://www.tiktok.com/@geomeets"
            />
            <LinkButton
              title="Reddit"
              icon={<RedditIcon />}
              url="https://www.reddit.com/r/geomeets/"
            />
          </ButtonContainer>
          <Line sep={3} /> */}
          <Heading size={18} sep={1}>Legal</Heading>
          <Body size={15} center sep={2}>
            See how we operate and handle your data
          </Body>
          <ButtonContainer>
            <ActionButton
              variant="outlined"
              startIcon={<GavelIcon />}
              onClick={() => setTerms(true)}
            >
              Terms and Conditions
            </ActionButton>
            <ActionButton
              variant="outlined"
              startIcon={<VerifiedUserIcon />}
              onClick={() => setPrivacy(true)}
            >
              Privacy Policy
            </ActionButton>
          </ButtonContainer>
        </GeneralContainer>
      </Container>
      <CopyrightText>GeoMeets © 2023</CopyrightText>
    </Template>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

// const LinkButton = (props: {
//   title: string;
//   icon: React.ReactNode;
//   url: string;
//   disabled?: boolean;
// }) => {
//   return (
//     <ActionButton
//       disabled={props.disabled}
//       variant="outlined"
//       startIcon={props.icon}
//       onClick={() => Utils.openUrlInNewTab(props.url)}
//     >
//       {props.title}
//     </ActionButton>
//   )
// }

const FAQElement = (props: {
  id: number;
  opened: number;
  setOpened: (arg: number) => void;
  question: string;
  answer: string;
}) => {
  const { id, question, answer, opened, setOpened } = props

  return (
    <SlideDown
      expanded={opened === id}
      onChange={(_, isExpanded: boolean) => setOpened(isExpanded ? id : -1)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{question}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{answer}</Typography>
      </AccordionDetails>
    </SlideDown>
  )
}

const FAQs = (props: {
  faqs: FAQProps[]
}) => {
  const [opened, setOpened] = useState<number>(-1);

  return (
    <>
      {
        props.faqs.map((faq, index) => (
          <FAQElement
            id={index}
            setOpened={setOpened}
            opened={opened}
            {...faq}
          />
        ))
      }
    </>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Container = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  width: { xs: '95%', sm: '70%' },
  maxWidth: { xs: 360, sm: 700 },
}))

const ButtonContainer = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  flexWrap: 'wrap'
}))

const GeneralContainer = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  backgroundColor: 'white',
  p: 4,
  pb: 6,
  border: 0.5,
  borderColor: '#8c8c8c',
  borderRadius: 3
}))

const SlideDown = styled(Accordion)(sx({
  width: '100%',
  border: 0.5,
  boxShadow: 0,
  borderColor: '#8c8c8c',
  borderRadius: 2
}))

const ActionButton = styled(Button)(sx({
  m: 0.5
}))

const CopyrightText = styled(Typography)(sx({
  mt: 3,
  fontSize: 14,
  fontStyle: 'italic'
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Info;
