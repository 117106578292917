//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////
// Constant(s)
//////////////////////////////////////////////////////////////////////////////////////////

const firebaseProduction = {
  apiKey: "AIzaSyBVmXv93cPUdTnpst8WmTv2vvcZNcrzhcY",
  authDomain: "geomeets.firebaseapp.com",
  projectId: "geomeets",
  storageBucket: "geomeets.appspot.com",
  messagingSenderId: "350911079622",
  appId: "1:350911079622:web:1a01d529421a8ed36663c7",
  measurementId: "G-E73P3VZKJ9"
};

//////////////////////////////////////////////////////////////////////////////////////////
// Class(es) & Function(s)
//////////////////////////////////////////////////////////////////////////////////////////

class Configs {
  public static production: boolean = process.env.REACT_APP_ENV === 'production'
  public static development: boolean = !this.production

  public static email: string = 'redacted@invalid.com'

  public static url: string = this.production
    ? 'https://geomeets.com'
    : 'http://localhost:3000'

  public static firebase: object = this.production
    ? firebaseProduction
    : firebaseProduction // change this later

  public static version: string = '1.0.0'
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Configs
