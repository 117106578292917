//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { Box, FormControl, LinearProgress, Pagination } from "@mui/material";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import Template from "../components/templates/Template";
import SearchIcon from '@mui/icons-material/Search';
import { sx, Utils } from "../utils";
import { MeetsDropdown, MeetsTypes } from "../components/elements/Meets";
import { LoadingButton } from "@mui/lab";
import { Display } from "../components/elements/Display";
import { Line } from "../components/elements/Line";
import { NoResults } from "../components/templates/NoResults";
import Firebase from "../firebase";
import { Search } from "../components/elements/Search";
import { Spinner } from "../components/elements/Spinner";
import { uuidv4 } from "@firebase/util";
import Firestore, { MeetsResponseProps } from "../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Main = () => {
  const [response, setResponse] = useState<MeetsResponseProps>()
  const [loading, setLoading] = useState<boolean>(false)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)

  const categoriesRef = useRef<string[]>([])
  const meetsRef = useRef<MeetsTypes>(MeetsTypes.ALL)

  const fetch = async (page: number) => {
    const meets = await Firestore.getMeets(categoriesRef.current, meetsRef.current, page)
    setResponse(meets)
    if (Firebase.auth.currentUser) {
      const account = await Firestore.getAccount()
      meets.pages.items = Utils.addBookmarksAndLikesToMeets(
        account.likes,
        account.bookmarks,
        meets.pages.items
      )
      setResponse({ ...meets })
    }
  }

  const search = async () => {
    setLoading(true)
    await fetch(1)
    setLoading(false)
  }

  useEffect(() => {
    (async () => {
      Firebase.onAuthChange(async () => await fetch(1))
    })()
  }, [])

  if (response === undefined) {
    return (
      <Template center>
        <Spinner />
      </Template>
    )
  }

  return (
    <Template>
      <SearchContainer>
        <SearchBar>
          <Search
            title="Search"
            categoriesRef={categoriesRef}
          />
        </SearchBar>
        <WrappedContainer>
          <Dropdown>
            <MeetsDropdown
              includeAll
              label="Type"
              defaultMeet={meetsRef.current}
              onChange={e => meetsRef.current = e.target.value as MeetsTypes}
            />
          </Dropdown>
          <SearchButton
            variant="outlined"
            onClick={search}
            loading={loading}
          >
            <SearchIcon />
          </SearchButton>
        </WrappedContainer>
      </SearchContainer>
      <Line sep={2} label={`${response.search.total} Meets`} />
      <WrappedContainer sx={{ width: '100%' }}>
        {
          response.pages.items.length > 0
            ? response.pages.items.map(data => (<Display data={data} key={uuidv4()} />))
            : <NoResults />
        }
      </WrappedContainer>
      <PaginationContainer>
        {
          paginationLoading
            ? <LoadingLine />
            : <Pagination
              count={Utils.calcPaginationEndPage(
                response.pages.current,
                response.pages.end
              )}
              defaultPage={response.pages.current}
              onChange={async (_, page) => {
                setPaginationLoading(true)
                await fetch(page)
                setPaginationLoading(false)
              }}
            />
        }
      </PaginationContainer>
    </Template>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const SearchContainer = styled(Box)(sx({
  display: 'flex',
  width: '100%',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

const WrappedContainer = styled(Box)(sx({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap'
}))

const PaginationContainer = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  maxWidth: 380,
  minHeight: 50,
  mt: 1,
}))

const SearchButton = styled(LoadingButton)(sx({
  backgroundColor: 'white',
  height: 58,
  m: 0.5
}))

const Dropdown = styled(FormControl)(sx({
  width: 180,
  backgroundColor: 'white',
  m: 0.5
}))

const SearchBar = styled(Box)(sx({
  width: { xs: '90%', md: 'calc(100% - 290px)' },
  minWidth: 180,
  m: 0.5
}))

const LoadingLine = styled(LinearProgress)(sx({
  width: '80%'
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Main;
