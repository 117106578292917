//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  AccountResponseProps,
  BookmarksResponseProps,
  CategoriesResponseProps,
  MeetsResponseProps,
  MyMeetsResponseProps
} from "../firebase/firestore";
import { Utils } from "../utils";

//////////////////////////////////////////////////////////////////////////////////////////
// Type(s)
//////////////////////////////////////////////////////////////////////////////////////////

interface LocalStorageProps<T> {
  expire: string;
  value?: T;
}

//////////////////////////////////////////////////////////////////////////////////////////
// Sub-Class(es)
//////////////////////////////////////////////////////////////////////////////////////////

class LocalStorage<T> {

  key: string;
  expire: number;

  constructor(key: string, expire: number) {
    this.key = key
    this.expire = expire
  }

  private createExpireISO = () => {
    return new Date(Date.now() + this.expire).toString()
  }

  private isISOExpired = (ISOtime: string) => {
    return new Date(ISOtime).valueOf() - Date.now() <= 0
  }

  public get = (): T | undefined => {
    let item: LocalStorageProps<T>
    try {
      item = JSON.parse(
        window
          .localStorage
          .getItem(this.key)
        || ''
      )
    } catch { return undefined }
    return this.isISOExpired(item.expire)
      ? undefined
      : item.value
  }

  public set = (value: T): void => {
    try {
      window.localStorage.setItem(
        this.key,
        JSON.stringify({
          expire: this.createExpireISO(),
          value
        })
      )
    } catch { }
  }

  public unset = (): void => {
    window.localStorage.removeItem(this.key)
  }

}

//////////////////////////////////////////////////////////////////////////////////////////
// Class(es)
//////////////////////////////////////////////////////////////////////////////////////////

class Cache {
  static meets = new LocalStorage<MeetsResponseProps>(
    'meets',
    Utils.minutes(1)
  )
  static categories = new LocalStorage<CategoriesResponseProps>(
    'categories',
    Utils.minutes(30)
  )
  static mymeets = new LocalStorage<MyMeetsResponseProps>(
    'mymeets',
    Utils.minutes(5)
  )
  static bookmarks = new LocalStorage<BookmarksResponseProps>(
    'bookmarks',
    Utils.minutes(5)
  )
  static account = new LocalStorage<AccountResponseProps>(
    'account',
    Utils.minutes(5)
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Cache;
