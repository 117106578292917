//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  deleteUser,
  onAuthStateChanged,
  User
} from "firebase/auth";
import { Utils } from "../utils";
import { toast } from "react-toastify";
import Configs from "../configs";
import { auth } from "./app";
import Firestore from "./firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Firebase Setup
//////////////////////////////////////////////////////////////////////////////////////////

const actionCodeSettings = {
  url: Configs.url + '/validate' || '',
  handleCodeInApp: true
};

//////////////////////////////////////////////////////////////////////////////////////////
// Class(es) & Function(s)
//////////////////////////////////////////////////////////////////////////////////////////

class Firebase {
  private static firebaseWrapper = async<T>(
    action: () => Promise<T>,
    initial: T,
    requireLogin = false
  ) => {
    requireLogin && !this.auth.currentUser
      ? toast.error('User is not logged in')
      : await action()
        .then(data => { initial = data })
        .catch(e => toast.error(Utils.parseFirebaseError(e.message)))
    return initial
  }

  static auth = auth

  static GoogleSignIn = async (): Promise<boolean> => {
    return await this.firebaseWrapper<boolean>(
      async () => {
        await signInWithPopup(this.auth, new GoogleAuthProvider())
        await Firestore.postAccount()
        return true
      },
      false
    )
  }

  static SendSignInEmail = async (email: string): Promise<boolean> => {
    window.localStorage.setItem('emailForSignIn', email)
    return await this.firebaseWrapper<boolean>(
      async () => {
        await sendSignInLinkToEmail(this.auth, email, actionCodeSettings)
        return true
      },
      false
    )
  }

  static signInWithLink = async (link: string): Promise<boolean> => {
    let email = window.localStorage.getItem('emailForSignIn')
    if (!email) email = window.prompt('Please type in your email again to verify')
    const res = await this.firebaseWrapper<boolean>(
      async () => {
        await signInWithEmailLink(this.auth, email || '', link)
        return true
      },
      false
    )
    window.localStorage.removeItem('emailForSignIn')
    return res
  }

  static signOut = async (): Promise<boolean> => {
    return await this.firebaseWrapper<boolean>(
      async () => {
        await this.auth.signOut()
        return true
      },
      false
    )
  }

  static deleteAccount = async (): Promise<boolean> => {
    return await this.firebaseWrapper<boolean>(
      async () => {
        if (this.auth.currentUser) {
          if (!await Firestore.deleteAccount()) return false
          await deleteUser(this.auth.currentUser)
        }
        return true
      },
      false,
      true
    )
  }

  static onAuthChange = (action: (user: User | null) => void | Promise<void>) => {
    onAuthStateChanged(this.auth, async (user) => {
      await action(user)
    })
  }

  static isSignInLinkValid = (link: string) => {
    return isSignInWithEmailLink(this.auth, link)
  }

  static isRecentLogin = (): boolean => {
    const user = this.auth.currentUser
    if (!user) return false
    const lastSignIn = new Date(user.metadata.lastSignInTime || '').valueOf()
    const currentTime = new Date().valueOf()
    return (currentTime - lastSignIn) <= Utils.minutes(4)
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Firebase;
