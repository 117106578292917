//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import Firebase from "../firebase";
import styled from "@emotion/styled";
import Template from "../components/templates/Template";
import { sx } from "../utils";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Dialog } from "../components/elements/Dialog";
import AuthTemplate from "../components/templates/AuthTemplate";
import { Body } from "../components/elements/Text";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';

//////////////////////////////////////////////////////////////////////////////////////////
// Main Page Component
//////////////////////////////////////////////////////////////////////////////////////////

const Auth = () => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false)

  Firebase.onAuthChange(user => {
    if (user && !loggedIn) setLoggedIn(true)
    else if (!user && loggedIn) setLoggedIn(false)
  })

  return (
    <Template center>
      {
        loggedIn
          ? <SignOutPrompt />
          : <SignInPrompt />
      }
    </Template>
  );
}

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const SignInPrompt = () => {
  // const emailRef = useRef<string>('')
  // const [loading, setLoading] = useState<boolean>(false)

  // const sendSignInEmail = async () => {
  //   setLoading(true)
  //   if (await Firebase.SendSignInEmail(emailRef.current)) {
  //     toast.success('Email Link Sent Successfully')
  //   }
  //   setLoading(false)
  // }

  return (
    <AuthTemplate
      googleSignIn logo
      title="Create Account or Sign In"
      subtitle="Create an account or sign in via Google to post and save meets"
    >
      {/* <Input
        small
        title="Email"
        icon={<MailOutlineIcon />}
        inputRef={emailRef}
        sx={{ width: '85%', mb: 1, mt: 1 }}
      />
      <Button
        variant="outlined"
        loading={loading}
        onClick={sendSignInEmail}
        endIcon={<SendIcon />}
      >
        Send Sign In Email
      </Button> */}
    </AuthTemplate>
  )
}

const SignOutPrompt = () => {
  const navigate = useNavigate()

  const [deleting, setDeleting] = useState<boolean>(false)
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false)

  const deleteAccount = async () => {
    setDeleting(true)
    if (Firebase.isRecentLogin()) {
      if (await Firebase.deleteAccount()) {
        toast.success('Successfully Deleted Account')
        navigate('/')
      }
    } else {
      toast.error("Deleting Account Requires a Recent Login")
    }
    setDeleting(false)
  }

  const signOut = async () => {
    if (await Firebase.signOut()) {
      toast.success('Successfully Logged Out')
      navigate('/')
    }
  }

  return (
    <AuthTemplate
      title="Account Settings"
      subtitle={`Manage your account for ${Firebase.auth.currentUser?.email}`}
    >
      <Dialog
        title="Permanently Delete Account?"
        open={deleteDialog}
        setOpen={setDeleteDialog}
        action={deleteAccount}
        content={
          `This action is irreversible. All your meetings, 
          likes and bookmarks will be permanently deleted.`
        }
        yes='Delete'
        no='Cancel'
      />
      <Body size={12} underline sep={1.5} bold>
        Account Actions
      </Body>
      <Button
        variant="outlined"
        startIcon={<LogoutIcon />}
        onClick={signOut}
      >
        Sign Out
      </Button>
      <Button
        variant="outlined"
        startIcon={<DeleteOutlineIcon />}
        onClick={() => setDeleteDialog(true)}
        loading={deleting}
      >
        Delete Account
      </Button>
      <Body size={12} underline sep={1.5} bold sx={{ mt: 2 }}>
        Other Actions
      </Body>
      <Button
        variant="outlined"
        startIcon={<HomeIcon />}
        onClick={() => navigate('/')}
      >
        Return home
      </Button>
    </AuthTemplate>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Button = styled(LoadingButton)(sx({
  width: '85%',
  mb: 1
}))

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Auth;
