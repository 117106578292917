//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { SxProps, Typography } from "@mui/material";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Heading = (props: {
  size?: number;
  underline?: boolean;
  bold?: boolean;
  sep?: number;
  center?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Typography sx={{
      textAlign: props.center ? 'center' : undefined,
      textDecorationLine: props.underline ? 'underline' : undefined,
      fontWeight: props.bold ? 'bold' : undefined,
      mb: props.sep || 0,
      fontSize: props.size || 20,
      ...props.sx
    }}>
      {props.children}
    </Typography>
  )
}

export const Body = (props: {
  size?: number;
  underline?: boolean;
  bold?: boolean;
  sep?: number;
  center?: boolean;
  children?: React.ReactNode;
  sx?: SxProps;
}) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{
        textAlign: props.center ? 'center' : undefined,
        textDecorationLine: props.underline ? 'underline' : undefined,
        fontWeight: props.bold ? 'bold' : undefined,
        mb: props.sep || 0,
        fontSize: props.size || 16,
        ...props.sx
      }}
    >
      {props.children}
    </Typography>
  )
}
