//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  DocumentData, FirestoreError, QueryDocumentSnapshot, arrayRemove, arrayUnion, collection,
  deleteDoc, doc, getDocs, increment, limit, orderBy, query, setDoc, updateDoc, where
} from "firebase/firestore";
import { CategoryProps, DisplayProps } from "../components/elements/Display";
import { MeetsProps, MeetsTypes } from "../components/elements/Meets";
import { toast } from "react-toastify";
import { auth, firestore } from "./app";
import Cache from "../cache";

//////////////////////////////////////////////////////////////////////////////////////////
// Type(s)
//////////////////////////////////////////////////////////////////////////////////////////

export type MeetsResponseProps = {
  pages: {
    current: number;
    end: number;
    items: DisplayProps[];
  },
  search: {
    categories: string[];
    total: number;
  }
}

export type AccountResponseProps = {
  email: string;
  likes: string[];
  bookmarks: string[];
  listings: string[];
}

export type CategoriesResponseProps = CategoryProps[];
export type BookmarksResponseProps = DisplayProps[];
export type MyMeetsResponseProps = MeetsProps[];

//////////////////////////////////////////////////////////////////////////////////////////
// Constants
//////////////////////////////////////////////////////////////////////////////////////////

export const MAX_REPORTS = 1
export const LIMIT_PER_PAGE = 30

//////////////////////////////////////////////////////////////////////////////////////////
// Class(es) & Function(s)
//////////////////////////////////////////////////////////////////////////////////////////

class Firestore {
  static firestore = firestore

  private static firestoreWrapper = async <T>(
    func: () => Promise<T>
  ): Promise<T | undefined> => {
    try {
      return await func()
    } catch (e) {
      const err = e as FirestoreError
      const message = err.message ?? 'There was an unexpected error'
      console.log(message)
      toast.warning(message)
    }
  }

  private static getMeetAtIndex = async (
    index: number
  ): Promise<QueryDocumentSnapshot<DocumentData> | undefined> => {
    const meetsCollection = collection(this.firestore, "listings")
    const searchQeury = [
      where('active', '==', true),
      orderBy("likes", "desc"),
      // limit(LIMIT_PER_PAGE)
    ]
    const meetsQuery = query(meetsCollection, ...searchQeury)
    try {
      const snapshot = await getDocs(meetsQuery)
      return snapshot.docs[Math.min(index, snapshot.docs.length - 1)]
    } catch { }
  }

  static getMeets = async (
    categories: string[] = [],
    type: MeetsTypes = MeetsTypes.ALL,
    page: number = 1
  ): Promise<MeetsResponseProps> => {
    const meets: MeetsResponseProps = {
      pages: { current: 0, end: 0, items: [] },
      search: { categories: [], total: 0 }
    }

    const isMainPage = categories.length === 0
      && type === MeetsTypes.ALL
      && page === 1

    const cache = Cache.meets.get()
    if (cache && isMainPage) return cache

    const categoriesQuery = categories.length ? [where('categories', 'array-contains-any', categories)] : []
    const typeQuery = type === MeetsTypes.ALL ? [] : [where('type', '==', type)]
    const searchQeury = [
      where('active', '==', true),
      orderBy("likes", "desc"),
      // startAt(startDoc),
      // limit(LIMIT_PER_PAGE)
    ]

    const response = await this.firestoreWrapper(
      async () => {
        const meetsCollection = collection(this.firestore, "listings")
        const meetsQuery = query(meetsCollection, ...categoriesQuery, ...typeQuery, ...searchQeury)
        return await getDocs(meetsQuery)
      }
    )

    let i = 0
    const start = (page - 1) * LIMIT_PER_PAGE
    const end = page * LIMIT_PER_PAGE - 1
    response?.forEach((doc) => {
      if (start <= i && i <= end) {
        const meet = doc.data() as DisplayProps & MeetsProps
        if (meet.reports.length < MAX_REPORTS) {
          meets.pages.items.push(meet)
        }
      }
      i++
    })

    const count = response?.docs.length ?? 0
    meets.search.total = count
    meets.search.categories = categories
    meets.pages.end = Math.ceil(count / LIMIT_PER_PAGE)
    meets.pages.current = Math.min(meets.pages.end, page)

    if (response && isMainPage) Cache.meets.set(meets)

    return meets
  }

  static getCategories = async (): Promise<CategoriesResponseProps> => {
    const categories: CategoriesResponseProps = []

    const cache = Cache.categories.get()
    if (cache) return cache

    const searchQeury = [
      where('active', '==', true),
      orderBy("likes", "desc"),
      limit(50)
    ]

    const response = await this.firestoreWrapper(
      async () => {
        const meetsCollection = collection(this.firestore, "listings")
        const meetsQuery = query(meetsCollection, ...searchQeury)
        return await getDocs(meetsQuery)
      }
    )

    const categoryAggregate: { [key: string]: number } = {}

    response?.forEach((doc) => {
      const meet = doc.data() as DisplayProps & MeetsProps
      const categories = meet.categories
      categories.forEach(category => {
        if (category in categoryAggregate) {
          categoryAggregate[category] += 1
        } else {
          categoryAggregate[category] = 1
        }
      })
    })

    Object.entries(categoryAggregate).forEach(([key, value]) => {
      categories.push({ category: key, count: value })
    })

    categories.sort((a, b) => b.count - a.count)

    if (response) Cache.categories.set(categories)

    return categories
  }

  static getSingleMeets = async (lid: string): Promise<MeetsResponseProps> => {
    const meets: MeetsResponseProps = {
      pages: { current: 0, end: 0, items: [] },
      search: { categories: [], total: 0 }
    }

    const response = await this.firestoreWrapper(
      async () => {
        const meetsCollection = collection(this.firestore, "listings")
        const meetsQuery = query(meetsCollection, where('lid', '==', lid))
        return await getDocs(meetsQuery)
      }
    )

    response?.forEach((doc) => {
      const meet = doc.data() as DisplayProps
      meets.pages.items.push(meet)
    })

    return meets
  }

  static getBookmarks = async (): Promise<BookmarksResponseProps> => {
    const uid = auth.currentUser?.uid
    const bookmarks: BookmarksResponseProps = []

    if (!uid) return bookmarks

    const cache = Cache.bookmarks.get()
    if (cache) return cache

    const user = await this.getAccount()
    const bookmarkIds = user.bookmarks

    if (!bookmarkIds.length) return bookmarks

    const response = await this.firestoreWrapper(
      async () => {
        const meetsCollection = collection(this.firestore, "listings")
        const meetsQuery = query(meetsCollection, where('lid', 'in', bookmarkIds.slice(0, 10)))
        return await getDocs(meetsQuery)
      }
    )

    response?.forEach((doc) => {
      const meet = doc.data() as DisplayProps & MeetsProps
      if (meet.reports.length < MAX_REPORTS) {
        bookmarks.push(meet)
      }
    })

    if (response) Cache.bookmarks.set(bookmarks)

    return bookmarks
  }

  static postSetBookmark = async (bookmark: boolean, lid: string): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    const response = await this.firestoreWrapper(
      async () => {
        const listingRef = doc(this.firestore, "listings", lid);
        await updateDoc(listingRef, { bookmarks: bookmark ? increment(1) : increment(-1) })
        const userRef = doc(this.firestore, "users", uid);
        await updateDoc(userRef, { bookmarks: bookmark ? arrayUnion(lid) : arrayRemove(lid) })
        return true
      }
    )

    if (response) Cache.bookmarks.unset()
    if (response) Cache.account.unset()

    return !!response
  }

  static postSetLike = async (like: boolean, lid: string): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    const response = await this.firestoreWrapper(
      async () => {
        const listingRef = doc(this.firestore, "listings", lid);
        await updateDoc(listingRef, { likes: like ? increment(1) : increment(-1) })
        const userRef = doc(this.firestore, "users", uid);
        await updateDoc(userRef, { likes: like ? arrayUnion(lid) : arrayRemove(lid) })
        return true
      }
    )

    if (response) Cache.bookmarks.unset()
    if (response) Cache.account.unset()

    return !!response
  }

  static postReport = async (lid: string, reason: string): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    const response = await this.firestoreWrapper(
      async () => {
        const userRef = doc(this.firestore, "listings", lid);
        await updateDoc(userRef, { reports: arrayUnion(uid) })
        return true
      }
    )

    return !!response
  }

  static getMyMeets = async (): Promise<MyMeetsResponseProps> => {
    const meets: MyMeetsResponseProps = []

    const uid = auth.currentUser?.uid
    if (!uid) return meets

    const cache = Cache.mymeets.get()
    if (cache) return cache

    const response = await this.firestoreWrapper(
      async () => {
        const user = await this.getAccount()
        const listings = user.listings

        const listingsQuery = listings.length ? [where('lid', 'in', listings.slice(0, 10))] : []
        const searchQeury = [
          orderBy("likes", "desc"),
          // startAt(startDoc),
          // limit(LIMIT_PER_PAGE)
        ]

        const meetsCollection = collection(this.firestore, "listings")
        const meetsQuery = query(meetsCollection, ...listingsQuery, ...searchQeury)
        return await getDocs(meetsQuery)
      }
    )

    response?.forEach((doc) => {
      const meet = doc.data() as MeetsProps
      meets.push(meet)
    })

    if (response) Cache.mymeets.set(meets)

    return meets
  }

  static putMyMeets = async (meet: MeetsProps): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    if (meet.type === MeetsTypes.GOOGLE_MEET && !meet.url.startsWith('https://meet.google.com/')) {
      toast.warn('Invalid google meet link')
      return false
    }

    if (meet.type === MeetsTypes.TEAMS && !meet.url.startsWith('https://teams.microsoft.com/')) {
      toast.warn('Invalid teams link')
      return false
    }

    if (meet.type === MeetsTypes.ZOOM && !meet.url.startsWith('https://zoom.us/')) {
      toast.warn('Invalid zoom link')
      return false
    }

    if (
      meet.type === MeetsTypes.DISCORD
      && !(meet.url.startsWith('https://discord.gg/') || meet.url.startsWith('https://discord.com/'))) {
      toast.warn('Invalid discord link')
      return false
    }

    const response = await this.firestoreWrapper(
      async () => {
        const userRef = doc(this.firestore, "users", uid);
        await updateDoc(userRef, { listings: arrayUnion(meet.lid) })
        const meetsDoc = doc(this.firestore, "listings", meet.lid)
        await setDoc(meetsDoc, {
          likes: 0,
          bookmarks: 0,
          date: new Date().toISOString(),
          ...meet,
        })
        return true
      }
    )

    if (response) Cache.mymeets.unset()
    if (response) Cache.meets.unset()

    return !!response
  }

  static deleteMyMeets = async (lid: string): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    const response = await this.firestoreWrapper(
      async () => {
        const userRef = doc(this.firestore, "users", uid);
        await updateDoc(userRef, { listings: arrayRemove(lid) })
        const meetsDoc = doc(this.firestore, "listings", lid)
        await deleteDoc(meetsDoc)
        return true
      }
    )

    if (response) Cache.mymeets.unset()

    return !!response
  }

  static getAccount = async (): Promise<AccountResponseProps> => {
    const uid = auth.currentUser?.uid
    let account: AccountResponseProps = {
      email: "",
      likes: [],
      bookmarks: [],
      listings: []
    }

    if (!uid) return account

    const cache = Cache.account.get()
    if (cache) return cache

    const response = await this.firestoreWrapper(
      async () => {
        const usersCollection = collection(this.firestore, "users")
        const usersQuery = query(usersCollection, where('uid', '==', uid))
        return await getDocs(usersQuery)
      }
    )

    response?.forEach((doc) => {
      const user = doc.data() as AccountResponseProps
      account = user
    })

    if (response) Cache.account.set(account)

    return account
  }

  static postAccount = async (): Promise<boolean> => {
    const account: AccountResponseProps = {
      email: "",
      likes: [],
      bookmarks: [],
      listings: []
    }

    Cache.bookmarks.unset()
    Cache.mymeets.unset()
    Cache.account.unset()

    const uid = auth.currentUser?.uid
    if (!uid) return false

    const user = await this.getAccount()
    if (user.email) return true

    account.email = auth.currentUser?.email ?? ''

    await this.firestoreWrapper(
      async () => {
        const usersDoc = doc(this.firestore, "users", uid)
        return await setDoc(usersDoc, {
          ...account, uid
        })
      }
    )

    return true
  }

  static deleteAccount = async (): Promise<boolean> => {
    const uid = auth.currentUser?.uid
    if (!uid) return false

    Cache.bookmarks.unset()
    Cache.mymeets.unset()
    Cache.meets.unset()
    Cache.account.unset()

    const user = await this.getAccount()
    if (!user.email) {
      toast.error("User does not exist")
      return false
    }

    if (user.listings.length > 0) {
      toast.warn("Please delete all listings before deleting your account")
      return false
    }

    await this.firestoreWrapper(
      async () => {
        const usersDoc = doc(this.firestore, "users", uid)
        return await deleteDoc(usersDoc)
      }
    )

    return true
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
// Export(s)
//////////////////////////////////////////////////////////////////////////////////////////

export default Firestore;
