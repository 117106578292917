//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { Autocomplete, Box, SxProps, TextField, Typography } from "@mui/material";
import { createContext, forwardRef, useContext, useEffect, useRef, useState } from "react";
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { Utils } from "../../utils";
import { Spinner } from "./Spinner";
import Firestore from "../../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Search = (props: {
  categoriesRef: React.MutableRefObject<string[]>;
  title: string;
  limitTags?: number;
  defaultValue?: string[];
  small?: boolean;
  sx?: SxProps;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>()
  const loading = open && categories === undefined

  useEffect(() => {
    if (loading) {
      (async () => {
        setCategories(
          (await Firestore.getCategories())
            .map(item => item.category)
            .filter(item => !Utils.isProfanity(item))
        )
      })()
    }
  }, [loading])

  return (
    <Autocomplete
      multiple
      freeSolo
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={loading}
      defaultValue={[...props.defaultValue ?? []]}
      limitTags={props.limitTags}
      size={props.small ? "small" : undefined}
      options={categories ?? []}
      onChange={(_, val) => props.categoriesRef.current = val as string[]}
      sx={{ backgroundColor: 'white', ...props.sx }}
      role='listbox'                                                          // Virtualisation
      ListboxComponent={ListboxComponent}                                     // Virtualisation
      renderOption={(props, option) => [props, option] as React.ReactNode}    // Virtualisation
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.title}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading && <Spinner size={20} sx={{ mr: 1 }} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Virtualisation Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

const ListboxComponent = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>((props, ref) => {

  const { children, ...other } = props;
  const itemData: React.ReactNode[] = [];

  (children as React.ReactElement[]).forEach(
    (item: React.ReactNode & { children?: React.ReactNode[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  )

  const Row = (props: ListChildComponentProps) => {
    const { data, index, style } = props;
    const [dataProps, option] = data[index]
    return (
      <Typography
        sx={{ mt: 1 }}
        {...dataProps}
        style={style}
      >
        {option}
      </Typography>
    )
  }

  const OuterElementContext = createContext({});

  const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <Box ref={ref} {...props} {...outerProps} />;
  })

  const useResetCache = (data: number) => {
    const ref = useRef<VariableSizeList>(null)
    useEffect(() => {
      if (ref.current != null) ref.current.resetAfterIndex(0, true)
    }, [data])
    return ref
  }

  const itemCount = itemData.length;
  const itemSize = 48;
  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={Math.min(itemCount, 8) * itemSize}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {Row}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})
