//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { CircularProgress, SxProps } from "@mui/material";
import { Body } from "../elements/Text";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Spinner = (props: {
  text?: string;
  size?: number;
  sx?: SxProps;
}) => {
  return (
    <>
      <CircularProgress
        size={props.size}
        sx={props.sx}
      />
      {
        props.text
          ? <Body center sx={{ mt: 3, width: 100 }}>
            {props.text}
          </Body>
          : undefined
      }
    </>
  )
}
