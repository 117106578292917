//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import Configs from "../configs";

//////////////////////////////////////////////////////////////////////////////////////////
// Class(es) & Function(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const app = initializeApp(Configs.firebase);
export const auth = getAuth(app)
export const firestore = getFirestore(app)
