//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";
import { sx } from "../../utils";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const NoResults = (props: {
  text?: string;
}) => {
  return (
    <Container>
      <Icon />
      <Typography>
        {props.text ? props.text : 'No Results Found'}
      </Typography>
    </Container>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const Container = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: 150
}))

const Icon = styled(TravelExploreIcon)(sx({
  color: '#595959',
  fontSize: 40,
  mb: 2
}))