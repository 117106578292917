//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from "@mui/material"
import styled from "@emotion/styled";
import Zoom from '../../images/meets/zoom.png'
import GoogleMeet from '../../images/meets/google-meet.png'
import Teams from '../../images/meets/teams.png'
import Discord from '../../images/meets/discord.png'
import Other from '../../images/meets/other.png'
import All from '../../images/meets/all.png'
import { Globals, sx, Utils } from "../../utils";
import { ListingProps } from "./Display";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Dialog } from "./Dialog";
import { Body } from "./Text";
import { Input } from "./Input";
import { Search } from "./Search";
import { LoadingButton } from "@mui/lab";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ErrorIcon from '@mui/icons-material/Error';
import TheatersIcon from '@mui/icons-material/Theaters';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import { uuidv4 } from "@firebase/util";
import { Spinner } from "./Spinner";
import Configs from "../../configs";
import Firestore, { MAX_REPORTS } from "../../firebase/firestore";

//////////////////////////////////////////////////////////////////////////////////////////
// Type(s)
//////////////////////////////////////////////////////////////////////////////////////////

export enum MeetsTypes {
  ALL = 'all',
  GOOGLE_MEET = 'google-meet',
  TEAMS = 'teams',
  DISCORD = 'discord',
  ZOOM = 'zoom',
  OTHER = 'other',
}

export interface MeetsProps extends ListingProps {
  active: boolean;
  reports: string[];
}

//////////////////////////////////////////////////////////////////////////////////////////
// Functions(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const createEmptyMeets = (): MeetsProps => {
  return {
    lid: uuidv4(),
    title: '',
    categories: [],
    type: MeetsTypes.GOOGLE_MEET,
    reports: [],
    startTime: new Date().toISOString(),
    endTime: new Date().toISOString(),
    description: '',
    active: true,
    url: ''
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s) - Meets Card
//////////////////////////////////////////////////////////////////////////////////////////

export const MeetsCard = (props: {
  position: number;
  meet: MeetsProps;
}) => {
  const { meet, position } = props
  const [deleted, setDeleted] = useState<boolean>(false);
  const [menu, setMenu] = useState<HTMLElement>()
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onDelete = async () => {
    setLoading(true)
    if (await Firestore.deleteMyMeets(meet.lid)) {
      setDeleted(true)
      toast.success(`Successfully Deleted Meeting #${position}`)
    }
    setLoading(false)
  }

  const openDeleteDialog = () => {
    setDeleteDialog(true)
    setMenu(undefined)
  }

  const copyListingLink = () => {
    Utils.copyListingLink(meet.lid)
    setMenu(undefined)
  }

  if (deleted) return <></>

  return (
    <CardContainer>
      <Dialog
        title='Delete Meeting?'
        open={deleteDialog}
        setOpen={setDeleteDialog}
        action={onDelete}
        content={
          `Would you like to delete this meeting? This
          action is permanent and cannot be reversed.`
        }
        yes='Delete'
        no='Cancel'
      />
      <CardHeader
        avatar={<VideoFileIcon fontSize="large" sx={{ color: '#8c8c8c' }} />}
        action={
          <IconButton onClick={e => setMenu(e.currentTarget)}>
            <MoreVertIcon />
          </IconButton>
        }
        title={`Meeting #${position}`}
      />
      <Menu
        anchorEl={menu}
        open={!!menu}
        onClose={() => setMenu(undefined)}
        PaperProps={{ elevation: 0, sx: { border: 0.5, borderColor: '#8c8c8c' } }}
      >
        <MenuItem onClick={openDeleteDialog}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
        <MenuItem onClick={copyListingLink}>
          <ListItemIcon>
            <TheatersIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy Meet</ListItemText>
        </MenuItem>
      </Menu>
      <CardContentContainer>
        {
          loading
            ? <Spinner />
            : meet.reports.length < MAX_REPORTS
              ? <EditDisplay
                onDelete={onDelete}
                position={position}
                meet={meet}
              />
              : <QuarantinedDisplay />
        }
      </CardContentContainer>
    </CardContainer >
  )
}

export const ClearMeetsCard = (props: {
  children: React.ReactNode;
}) => {
  return (
    <ClearCardContainer>
      {props.children}
    </ClearCardContainer>
  )
}

const QuarantinedDisplay = () => {
  return (
    <>
      <ErrorIcon sx={{ fontSize: 60, color: '#8c8c8c', mb: 3 }} />
      <Body center sep={3}>
        This meeting was reported too many times. Please
        contact {Configs.email} for help.
      </Body>
    </>
  )
}

const EditDisplay = (props: {
  onDelete: () => Promise<void>;
  meet: MeetsProps;
  position: number;
}) => {
  const { meet, position } = props

  const titleRef = useRef<string>(meet.title)
  const descriptionRef = useRef<string>(meet.description)
  const urlRef = useRef<string>(meet.url)
  const categoriesRef = useRef<string[]>(meet.categories)
  const activeRef = useRef<boolean>(meet.active)
  const meetsRef = useRef<MeetsTypes>(meet.type)

  const [from, setFrom] = useState<string>(meet.startTime)
  const [to, setTo] = useState<string>(meet.endTime)
  const [updating, setUpdating] = useState<boolean>(false)

  const onUpdate = async () => {
    const data: MeetsProps = {
      ...meet,
      lid: meet.lid,
      title: titleRef.current,
      categories: categoriesRef.current,
      type: meetsRef.current,
      startTime: from,
      endTime: to,
      description: descriptionRef.current,
      active: activeRef.current,
      url: urlRef.current,
      reports: [],
    }
    setUpdating(true)
    if (await Firestore.putMyMeets(data)) {
      toast.success(`Successfully Updated Meeting #${position}`)
    }
    setUpdating(false)
  }

  return (
    <>
      <Input
        small
        title="Title"
        max={Globals.MAX_TITLE_LENGTH}
        inputRef={titleRef}
        sx={{ mb: 2 }}
      />
      <Input
        small
        multiline
        title="Description"
        max={Globals.MAX_DESCRIPTION_LENGTH}
        inputRef={descriptionRef}
        sx={{ mb: 2 }}
      />
      <Input
        small
        title="Meeting Link"
        max={Globals.MAX_URL_LENGTH}
        inputRef={urlRef}
        sx={{ mb: 2 }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <HorizontalCanvas sx={{ mb: 2 }}>
          <MobileTimePicker
            label="From"
            value={from}
            onChange={val => setFrom(val || from)}
            renderInput={(params) => <TextField {...params} sx={{ width: '49%' }} size="small" />}
            DialogProps={{ PaperProps: { sx: { borderRadius: 10, padding: { xs: 1, sm: 2 } } } }}
          />
          <MobileTimePicker
            label="To"
            value={to}
            onChange={val => setTo(val || to)}
            renderInput={(params) => <TextField {...params} sx={{ width: '49%' }} size="small" />}
            DialogProps={{ PaperProps: { sx: { borderRadius: 10, padding: { xs: 1, sm: 2 } } } }}
          />
        </HorizontalCanvas>
      </LocalizationProvider>
      <FormControl size="small" sx={{ width: '100%', mb: 2 }}>
        <MeetsDropdown
          label="Type"
          defaultMeet={meetsRef.current}
          onChange={e => meetsRef.current = e.target.value as MeetsTypes}
        />
      </FormControl>
      <Search
        small
        title="Categories"
        categoriesRef={categoriesRef}
        limitTags={2}
        defaultValue={categoriesRef.current}
        sx={{ mb: 0.5, width: '100%' }}
      />
      <HorizontalCanvas>
        <FormControlLabel
          value="bottom"
          label="Active"
          labelPlacement="bottom"
          sx={{ m: 1, mr: 2 }}
          control={
            <Switch
              size='small'
              defaultChecked={meet.active}
              onChange={e => activeRef.current = e.target.checked}
            />
          }
        />
        <LoadingButton
          onClick={onUpdate}
          variant='outlined'
          loading={updating}
          sx={{ width: '100%' }}
        >
          Update
        </LoadingButton>
      </HorizontalCanvas>
    </>
  )
}

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s) - DropDown
//////////////////////////////////////////////////////////////////////////////////////////

const Item = (props: {
  title: string;
  image: string;
}) => {
  return (
    <ItemCanvas>
      <img alt={props.title} src={props.image} style={{ height: 25 }} />
      <LabelText>{props.title}</LabelText>
    </ItemCanvas>
  )
}

export const MeetsDropdown = (props: {
  onChange: (e: SelectChangeEvent<MeetsTypes>) => void;
  defaultMeet: MeetsTypes;
  includeAll?: boolean;
  label?: string;
}) => {
  const { defaultMeet, label, onChange } = props

  return (
    <>
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue={defaultMeet ?? MeetsTypes.ALL}
        label={label}
        onChange={onChange}
      >
        {
          props.includeAll
            ? <MenuItem value={MeetsTypes.ALL}>
              <Item title="All" image={All} />
            </MenuItem>
            : undefined
        }
        <MenuItem value={MeetsTypes.GOOGLE_MEET}>
          <Item title="Google Meet" image={GoogleMeet} />
        </MenuItem>
        <MenuItem value={MeetsTypes.ZOOM}>
          <Item title="Zoom" image={Zoom} />
        </MenuItem>
        <MenuItem value={MeetsTypes.TEAMS}>
          <Item title="Teams" image={Teams} />
        </MenuItem>
        <MenuItem value={MeetsTypes.DISCORD}>
          <Item title="Discord" image={Discord} />
        </MenuItem>
        <MenuItem value={MeetsTypes.OTHER}>
          <Item title="Other" image={Other} />
        </MenuItem>
      </Select >
    </>
  )
}

export const meetsTypeToImage = (meets: MeetsTypes) => {
  switch (meets) {
    case MeetsTypes.DISCORD:
      return Discord
    case MeetsTypes.GOOGLE_MEET:
      return GoogleMeet
    case MeetsTypes.TEAMS:
      return Teams
    case MeetsTypes.ZOOM:
      return Zoom
    case MeetsTypes.OTHER:
      return Other
    default:
      return All
  }
}

//////////////////////////////////////////////////////////////////////////////////////////
// Style(s)
//////////////////////////////////////////////////////////////////////////////////////////

const CardContainer = styled(Card)(sx({
  maxWidth: 320,
  border: 0.5,
  borderColor: '#8c8c8c',
  boxShadow: 0,
  m: 0.5,
  width: '100%',
  borderRadius: 3,
  minHeight: 560
}))

const CardContentContainer = styled(CardContent)(sx({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  height: '80%'
}))

const ClearCardContainer = styled(CardContainer)(sx({
  border: 0,
  backgroundColor: 'transparent'
}))

const HorizontalCanvas = styled(Box)(sx({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%'
}))

const LabelText = styled(Typography)(sx({
  ml: 1.5
}))

const ItemCanvas = styled(Box)(sx({
  display: 'flex',
  alignItems: 'center'
}))
