//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import {
  Button,
  Dialog as MUIDialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

//////////////////////////////////////////////////////////////////////////////////////////
// Component(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const Dialog = (props: {
  open: boolean;
  setOpen: (arg: boolean) => void;
  title: string;
  action?: () => Promise<void> | void;
  content?: React.ReactNode;
  yes?: string;
  no?: string;
  dividers?: boolean;
}) => {
  const { open, setOpen, title, action, content, yes, no, dividers } = props

  return (
    <MUIDialog
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{ sx: { borderRadius: 5, border: 0.5, borderColor: '#8c8c8c', boxShadow: 0, padding: { xs: 1, sm: 2 } } }}
      slotProps={{ backdrop: { style: { backgroundColor: 'rgba(217, 217, 217, 0.5)' } } }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={dividers}>
        {content}
      </DialogContent>
      <DialogActions>
        {
          action
            ? <Button onClick={async () => {
              setOpen(false)
              if (action) action()
            }}>
              {yes ? yes : 'Yes'}
            </Button>
            : <></>
        }
        <Button onClick={() => setOpen(false)}>
          {no ? no : 'No'}
        </Button>
      </DialogActions>
    </MUIDialog>
  )
}
