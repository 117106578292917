//////////////////////////////////////////////////////////////////////////////////////////
// Imports
//////////////////////////////////////////////////////////////////////////////////////////

import { experimental_sx, SxProps } from "@mui/material";
import { toast } from "react-toastify";
import { Profanity } from "./profanity";
import { format } from "date-fns";
import { DisplayProps } from "../components/elements/Display";
import Configs from "../configs";

//////////////////////////////////////////////////////////////////////////////////////////
// Globals
//////////////////////////////////////////////////////////////////////////////////////////

export class Globals {
  static MAX_TITLE_LENGTH = 100
  static MAX_DESCRIPTION_LENGTH = 500
  static MAX_URL_LENGTH = 200
  static MAX_REPORT_REASON_LENGTH = 100
}

//////////////////////////////////////////////////////////////////////////////////////////
// Constants
//////////////////////////////////////////////////////////////////////////////////////////

const profanity = new Profanity()

//////////////////////////////////////////////////////////////////////////////////////////
// Utils
//////////////////////////////////////////////////////////////////////////////////////////

export class Utils {
  private static getTime = (time: Date): number => {
    return time.getHours() * this.hours(1)
      + time.getMinutes() * this.minutes(1)
      + time.getSeconds() * this.seconds(1)
  }

  static capitalise = (sentence: string, delimiter: string = ' ') => {
    var splitStr = sentence.toLowerCase().split(delimiter);
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  static parseFirebaseError = (error: string) => {
    let regExp = /\(auth\/(.+)\)/;
    let matches = regExp.exec(error);
    let final = matches?.[1].split('-').join(' ')
    return final ? this.capitalise(final) : error
  }

  static openUrlInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  static isValidISOString = (isoString: string) => {
    return !isNaN(Date.parse(isoString))
  }

  static calcPaginationEndPage = (current: number, end: number) => {
    if (end <= 6) return end
    if (current <= 4) return 6
    return Math.min(current + 2, end)
  }

  static copyListingLink = (lid: string) => {
    navigator.clipboard.writeText(
      `${Configs.url}/id/${lid}`
    )
    toast.success('Copied to Clipboard')
  }

  static copyMeetingLink = (url: string) => {
    navigator.clipboard.writeText(url)
    toast.success('Copied to Clipboard')
  }

  static addBookmarksAndLikesToMeets = (
    likes: string[],
    bookmarks: string[],
    meets: DisplayProps[]
  ): DisplayProps[] => {
    const likesSet = new Set(likes)
    const bookmarksSet = new Set(bookmarks)
    return meets.map(meet => {
      if (likesSet.has(meet.lid)) meet.liked = true
      if (bookmarksSet.has(meet.lid)) meet.bookmarked = true
      return meet
    })
  }

  static isMeetingNew = (dateISO: string): boolean => {
    return (Date.now() - new Date(dateISO).valueOf()) <= this.days(0)
  }

  static isMeetingLive = (startTimeISO: string, endTimeISO: string): boolean => {
    const currentTime = this.getTime(new Date())
    const startTime = this.getTime(new Date(startTimeISO))
    const endTime = this.getTime(new Date(endTimeISO))
    return startTime <= currentTime && endTime >= currentTime
  }

  static getDuration = (startTimeISO: string, endTimeISO: string): string => {
    const startTime = this.getTime(new Date(startTimeISO))
    const endTime = this.getTime(new Date(endTimeISO))

    const duration = endTime - startTime
    const hours = Math.floor(duration / this.hours(1))
    const minutes = Math.floor((duration - hours * this.hours(1)) / this.minutes(1))

    if (duration < 0) return 'Invalid Meeting Duration'

    const timeFields = []
    if (hours) timeFields.push(`${hours} hr${hours === 1 ? '' : 's'}`)
    if (minutes) timeFields.push(`${minutes} min${minutes === 1 ? '' : 's'}`)

    return duration.valueOf() === 0
      ? '24 hrs'
      : timeFields.join(' ') || '0 mins'
  }

  static formatTime = (time: string): string => {
    return format(new Date(time), 'p')
  }

  static formatDate = (date: string): string => {
    return format(new Date(date), 'LLL d, y')
  }

  static seconds = (seconds: number) => seconds * 1000

  static minutes = (minutes: number) => minutes * 60 * 1000

  static hours = (hours: number) => hours * 60 * 60 * 1000

  static days = (days: number) => days * 24 * 60 * 60 * 1000

  static maskProfanity = profanity.maskProfanity

  static isProfanity = profanity.isProfane
}

//////////////////////////////////////////////////////////////////////////////////////////
// Function(s)
//////////////////////////////////////////////////////////////////////////////////////////

export const sx = (styles: SxProps): TemplateStringsArray => {
  return experimental_sx(styles) as unknown as TemplateStringsArray
}