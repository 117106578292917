//////////////////////////////////////////////////////////////////////////////////////////
// Profanities List
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * @NOTE only add words that do not form part of a bigger word e.g. cum -> cumulative
 */
const blacklist: string[] = [
    "fuck",
    "shit",
    "cunt",
    "dick",
    "porn",
    "bitch",
    "faggot",
    "sex",
]

//////////////////////////////////////////////////////////////////////////////////////////
// Classes
//////////////////////////////////////////////////////////////////////////////////////////

export class Profanity {
    private searchRegex: RegExp = new RegExp('')
    private mapping: { [key: string]: string } = {}
    private blacklist: string[] = blacklist

    constructor() {
        this.blacklist.forEach(word => this.mapping[word] = '*'.repeat(word.length))
        this.searchRegex = new RegExp(this.blacklist.join('|'), "gi")
    }

    isProfane = (text: string): boolean => {
        return text.search(this.searchRegex) >= 0
    }

    maskProfanity = (text: string): string => {
        return text.replace(this.searchRegex, match => this.mapping[match])
    }
}